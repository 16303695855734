import { render, staticRenderFns } from "./EntryStatsListTabChipDrawDown.vue?vue&type=template&id=6648a7db&scoped=true&"
import script from "./EntryStatsListTabChipDrawDown.vue?vue&type=script&lang=ts&"
export * from "./EntryStatsListTabChipDrawDown.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "6648a7db",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
installComponents(component, {VChip})
