
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { Market } from "api/models";

@Component({})
export default class MarketExSelector extends Vue {
  @Prop()
  allMarkets?: Array<Market>;

  @Prop()
  markets?: Market[];

  @Prop()
  candleSize?: string;

  @Prop()
  from?: string;

  @Prop()
  to?: string;

  @Prop({ default: false })
  loading?: boolean;
}
