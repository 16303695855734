
//import "echarts";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

const index = {
  date: 0,
  positiveValue: 1,
  negativeValue: 2,
  sumBar: 3,
  closePrice: 4,
};

@Component
export default class SumProfit extends Vue {
  @Prop()
  barData: any;

  @Prop()
  singleMarket?: boolean;

  @Watch("barData")
  @Watch("singleMarket")
  onBarDataChanged() {
    this.initChart();
  }

  mounted() {
    this.initChart();
  }

  initChart() {
    this.options.dataset.source = this.barData;

    if (this.singleMarket) {
      if (this.options.legend.data.length === 1) {
        this.options.legend.data.push({
          name: "hodl profit",
        });
      }
    } else {
      if (this.options.legend.data.length === 2) {
        this.options.legend.data.pop();
      }
    }
  }

  private options = {
    animation: false,
    dataset: {
      source: [],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
    },
    legend: {
      bottom: "0",
      selected: {
        "hodl profit": false,
      },
      icon: "circle",
      data: [
        {
          name: "accumulated profit",
        },
      ],
    },
    grid: [
      {
        top: 20,
        left: "7%",
        right: "7%",
        bottom: 40,
      },
    ],
    xAxis: [
      {
        type: "category",
        scale: true,
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            opacity: 0.5,
          },
        },
      },
    ],
    yAxis: [
      {
        scale: true,
        splitLine: {
          lineStyle: {
            opacity: 0.4,
          },
        },
        axisTick: {
          show: false,
        },
        axisLabel: {
          formatter: "{value}%",
        },
        axisLine: {
          lineStyle: {
            opacity: 0.5,
          },
        },
      },
    ],
    series: [
      {
        name: "accumulated profit",
        type: "line",
        itemStyle: {
          color: "blue",
        },
        symbol: "none",
        encode: {
          x: index.date,
          y: index.sumBar,
        },
      },
      {
        name: "hodl profit",
        type: "line",
        itemStyle: {
          color: "gray",
        },
        lineStyle: {
          opacity: 0.3,
        },
        symbol: "none",
        encode: {
          x: index.date,
          y: index.closePrice,
        },
      },
    ],
  };
}
