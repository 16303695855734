
import Component from "vue-class-component";
import Vue from "vue";
import { mdiMenu } from "@mdi/js";

@Component({})
export default class StrategyOptimizerFinetuneTemplate extends Vue {
  leftBar = true;

  icons = {
    mdiHamburger: mdiMenu,
  };
}
