
import Component from "vue-class-component";
import Vue from "vue";
import TVChartContainer from "@/components/StrategyOptimizer/TVChartContainer.vue";
import { marketApi, strategyFinetuneApi, strategyOptimizerApi, strategyRunApi } from "@/plugins/api";
import {
  PostAppStrategyoptimizerHodlRequest,
  CalculateRequestStrategy,
  GetStrategyResponse,
  GetStrategyResponseStrategy,
  Market,
  PostAppStrategyrunCreateRequest,
  StatsDetailedResultAll,
  PostAppMarketQueryRequest,
  IndicatorConfig,
  CalculateRequestStage2,
  CalculateRequestStage2All,
} from "next-trader-api";
import { from, mergeMap, map, toArray, tap } from "rxjs";
import BarChart from "@/components/StrategyOptimizer/BarChart.vue";
import ColoredLabel from "@/components/StrategyOptimizer/ColoredLabel.vue";
import ExitChart from "@/components/StrategyOptimizer/ExitChart.vue";
import { StrategySelectorItem } from "@/components/StrategyOptimizer/model/StrategySelectorItem";
import EntryStatsTab from "@/components/StrategyOptimizer/EntryStatsTab.vue";
import EntryStatsListTab from "@/components/StrategyOptimizer/EntryStatsListTab.vue";
import MarketExSelector from "@/components/StrategyOptimizer/MarketExSelector.vue";
import { mdiAlertCircleOutline, mdiRocketLaunchOutline } from "@mdi/js";
import StrategyFinetunerResultItems from "@/components/StrategyFinetuner/StrategyFinetunerResultItems.vue";
import StrategyFinetunerStrategySelector from "@/components/StrategyFinetuner/StrategyFinetunerStrategySelector.vue";
import StrategyFinetunerPositions from "@/components/StrategyFinetuner/StrategyFinetunerPositions.vue";
import StrategyOptimizerFinetuneTemplate from "@/views/StrategyOptimizerFinetuneTemplate.vue";
import { cloneDeep } from "lodash-es";
import { switchMap } from "rxjs/operators";

export type Stage2ResultsByMarket = { market: Market; stage2Result: StatsDetailedResultAll | null };

@Component({
  components: {
    StrategyOptimizerFinetuneTemplate,
    StrategyFinetunerPositions,
    StrategyFinetunerStrategySelector,
    StrategyFinetunerResultItems,
    MarketExSelector,
    EntryStatsTab,
    EntryStatsListTab,
    ExitChart,
    ColoredLabel,
    BarChart,
    TVChartContainer,
  },
})
export default class Test extends Vue {
  from = "2021-01-01";
  to = "2021-06-30";
  markets: Market[] = [];
  candleSize = "5s"; //"5s";
  useCache = true;
  useStages = true;

  // defaultMarketIds: number[] | null = [
  //   6, 7, 8, 9, 10, 12, 14, 16, 17, 18, 21, 23, 29, 37, 40, 44, 48, 49, 51, 52, 53, 56, 78, 696, 781, 790, 837, 841,
  //   995, 1005, 1050, 1102,
  //   //781, 51,
  //   //6, 7
  // ];
  //defaultMarketIds: number[] | null = [6];
  defaultMarketIds: number[] | null = [6, 7, 8, 9];
  //defaultMarketIds: number[] | null = null;

  stage2ResultAll: StatsDetailedResultAll | null = null;
  stage2ResultsByMarket: Stage2ResultsByMarket[] = [];

  selectedMarket: Market | null = null;

  tab = 0;
  initialEntryStrategyIndex = 16;
  initialExitStrategyIndex = 1;

  entryStrategies: Array<GetStrategyResponseStrategy> = [];
  exitStrategies: Array<GetStrategyResponseStrategy> = [];
  allMarkets: Array<Market> = [];

  selectedEntryStrategy: StrategySelectorItem | null = null;
  selectedEntryStrategyDataStage1: CalculateRequestStrategy | null = null;
  selectedExitStrategy: StrategySelectorItem | null = null;

  entryIndicatorConfigs: Array<IndicatorConfig> = [];
  exitIndicatorConfigs: Array<IndicatorConfig> = [];

  weekHodlData = [];
  monthHodlData = [];

  showLong = true;

  loading = false;
  error = false;
  backtestLoading = false;

  updateEnabled = false;

  get loadingPercentage() {
    return (this.stage2ResultsByMarket.length / this.markets.length) * 100;
  }

  icons = {
    mdiRocketLaunchOutline: mdiRocketLaunchOutline,
    mdiAlertCircleOutline: mdiAlertCircleOutline,
  };

  mounted() {
    this.getMarkets().subscribe();
    this.getStrategies().subscribe();
  }

  updateStage2() {
    this.error = false;
    this.loading = true;

    const lastStage2ResultsByMarket = this.stage2ResultsByMarket;

    this.stage2ResultsByMarket = [];
    this.stage2ResultAll = null;
    this.clearPositions();

    const start = Date.now();

    from(lastStage2ResultsByMarket)
      .pipe(
        mergeMap((resultByMarket) => {
          const marketId = resultByMarket.market.id || 0;

          if (resultByMarket.stage2Result === null) {
            return this.emptyStage2(marketId);
          }
          //@ts-ignore
          return this.requestStage2(marketId);
        }, 16),
        toArray(),
        switchMap(() => {
          return this.requestStage2All();
        }),
        switchMap(() => {
          return this.requestIndicatorConfigs();
        })
      )
      .subscribe({
        error: () => {
          this.loading = false;
          this.error = true;
        },
        complete: () => {
          this.loading = false;
          const end = Date.now();
          console.log("Update", (end - start) / 1000 + "s");
        },
      });
  }

  calculateStage1() {
    if (!this.selectedEntryStrategy) {
      return;
    }

    this.error = false;
    this.loading = true;
    this.stage2ResultsByMarket = [];
    this.stage2ResultAll = null;
    this.clearPositions();

    this.selectedEntryStrategyDataStage1 = cloneDeep(this.selectedEntryStrategy.data);

    //TODO Do we need hodl data for the chart? Shall we load when the chart is displayed?
    //this.getHodl("1w");
    //this.getHodl("1mo");

    const start = Date.now();

    from(this.markets)
      .pipe(
        mergeMap((market) => {
          const fakeEntry = this.selectedEntryStrategy?.template.isFakeEntry;

          if (fakeEntry) {
            //@ts-ignore
            return this.requestFake(market.id);
          } else {
            if (this.useStages) {
              //@ts-ignore
              return this.requestStage1(market.id);
            } else {
              //@ts-ignore
              return this.requestSimple(market.id);
            }
          }
        }, 16),
        toArray(),
        switchMap(() => {
          return this.requestStage2All();
        }),
        switchMap(() => {
          return this.requestIndicatorConfigs();
        })
      )
      .subscribe({
        next: () => {
          this.updateEnabled = true;
        },
        error: () => {
          this.loading = false;
          this.error = true;
        },
        complete: () => {
          this.loading = false;
          const end = Date.now();
          console.log("Calculate", (end - start) / 1000 + "s");
        },
      });
  }

  requestStage1(marketId: number) {
    if (this.selectedEntryStrategy === null) {
      return Promise.reject("selectedEntryStrategy is null");
    }

    return strategyFinetuneApi
      .postAppStrategyfinetuneCalculatestatsstage1({
        calculateRequestStage2: this.getCalculateRequestStage2(marketId),
      })
      .pipe(
        map((stage2Result) => {
          const market: Market | undefined = this.markets.find((market) => market.id === marketId);
          if (market === undefined) {
            return;
          }

          this.stage2ResultsByMarket.push({
            market: market,
            stage2Result: stage2Result,
          });
          this.updatePositions(market);
        })
      );
  }

  requestSimple(marketId: number) {
    if (this.selectedEntryStrategy === null) {
      return Promise.reject("selectedEntryStrategy is null");
    }

    return strategyFinetuneApi
      .postAppStrategyfinetuneCalculatestatssimple({
        calculateRequestStage2: this.getCalculateRequestStage2(marketId),
      })
      .pipe(
        map((stage2Result) => {
          const market: Market | undefined = this.markets.find((market) => market.id === marketId);
          if (market === undefined) {
            return;
          }

          this.stage2ResultsByMarket.push({
            market: market,
            stage2Result: stage2Result,
          });
          this.updatePositions(market);
        })
      );
  }

  clearPositions() {
    const strategyFinetunerPositions = this.$refs.strategyFinetunerPositions as StrategyFinetunerPositions;
    strategyFinetunerPositions?.clearPositions();
  }

  updatePositions(market?: Market) {
    const strategyFinetunerPositions = this.$refs.strategyFinetunerPositions as StrategyFinetunerPositions;
    //NOTE This needs to be async, so that the strategyFinetunerPositions component will receive the updated variables
    //     before we run this code
    setTimeout(() => {
      if (market) {
        strategyFinetunerPositions?.onPositionsChangedByMarket(market);
      } else {
        strategyFinetunerPositions?.onPositionsChangedAll();
      }
    });
  }

  getCalculateRequestStage2(marketId: number) {
    const request: CalculateRequestStage2 = {
      from: this.from,
      to: this.to,
      candleSize: this.candleSize,
      marketId: marketId,
      useCache: this.useCache ? 1 : 0,
      entryStrategy: this.selectedEntryStrategy?.data || { name: "", params: [] },
      entryStrategyStage1: this.selectedEntryStrategyDataStage1 || { name: "", params: [] },
      exitStrategy: this.selectedExitStrategy?.data || { name: "", params: [] },
    };
    return request;
  }

  getCalculateRequestStage2All() {
    const request: CalculateRequestStage2All = {
      from: this.from,
      to: this.to,
      candleSize: this.candleSize,
      marketIds: this.markets.map((market) => market.id || 0),
      entryStrategy: this.selectedEntryStrategy?.data || { name: "", params: [] },
      exitStrategy: this.selectedExitStrategy?.data || { name: "", params: [] },
    };
    return request;
  }

  requestFake(marketId: number) {
    if (this.selectedEntryStrategy === null) {
      return Promise.reject("selectedEntryStrategy is null");
    }

    return strategyFinetuneApi
      .postAppStrategyfinetuneCalculatestatsfake({
        calculateRequestStage2: this.getCalculateRequestStage2(marketId),
      })
      .pipe(
        map((stage2Result) => {
          const market: Market | undefined = this.markets.find((market) => market.id === marketId);
          if (market === undefined) {
            return;
          }

          this.stage2ResultsByMarket.push({
            market: market,
            stage2Result: stage2Result,
          });
          this.updatePositions(market);
        })
      );
  }

  requestStage2(marketId: number) {
    if (this.selectedEntryStrategy === null) {
      return Promise.reject("selectedEntryStrategy is null");
    }

    return strategyFinetuneApi
      .postAppStrategyfinetuneCalculatestatsstage2({
        calculateRequestStage2: this.getCalculateRequestStage2(marketId),
      })
      .pipe(
        map((stage2Result) => {
          const market: Market | undefined = this.markets.find((market) => market.id === marketId);
          if (market === undefined) {
            return;
          }

          this.stage2ResultsByMarket.push({
            market: market,
            stage2Result: stage2Result,
          });
          this.updatePositions(market);
        })
      );
  }

  emptyStage2(marketId: number) {
    const market: Market | undefined = this.markets.find((market) => market.id === marketId);
    if (market === undefined) {
      return Promise.resolve();
    }

    this.stage2ResultsByMarket.push({
      market: market,
      stage2Result: null,
    });
    this.updatePositions(market);

    return Promise.resolve();
  }

  requestStage2All() {
    return strategyFinetuneApi
      .postAppStrategyfinetuneCalculatestatsstage2all({
        calculateRequestStage2All: this.getCalculateRequestStage2All(),
      })
      .pipe(
        tap((stage2ResultAll) => {
          this.stage2ResultAll = stage2ResultAll;
          this.updatePositions();

          if (this.$refs.entryStatsTab) {
            setTimeout(() => (this.$refs.entryStatsTab as EntryStatsTab).calculateBars(), 0);
          }
        })
      );
  }

  requestIndicatorConfigs() {
    return strategyFinetuneApi
      .postAppStrategyfinetuneGetindicatorconfigs({
        calculateRequestStage2All: this.getCalculateRequestStage2All(),
      })
      .pipe(
        tap((getIndicatorConfigsResult) => {
          this.entryIndicatorConfigs = getIndicatorConfigsResult.entryIndicatorConfigs;
          this.exitIndicatorConfigs = getIndicatorConfigsResult.exitIndicatorConfigs;
        })
      );
  }

  onClickOverview() {
    //NOTE: This is needed for the echarts to resize to the correct display
    setTimeout(() => {
      window.dispatchEvent(new Event("resize"));
    });
  }

  getStrategies() {
    return strategyOptimizerApi.getAppStrategyoptimizerGetstrategies().pipe(
      map((next: GetStrategyResponse) => {
        this.entryStrategies = next.entryStrategies ?? [];
        this.exitStrategies = next.exitStrategies ?? [];

        const entryStrategy = this.entryStrategies[this.initialEntryStrategyIndex];
        this.selectedEntryStrategy = {
          template: entryStrategy,
          data: {
            name: entryStrategy.name,
            params: entryStrategy.params.map((param) => param.defaultValue ?? ""),
          },
        };

        const exitStrategy = this.exitStrategies[this.initialExitStrategyIndex];
        this.selectedExitStrategy = {
          template: exitStrategy,
          data: {
            name: exitStrategy.name,
            params: exitStrategy.params.map((param) => param.defaultValue ?? ""),
          },
        };

        return next;
      })
    );
  }

  getMarkets() {
    const request: PostAppMarketQueryRequest = {
      marketQueryRequest: {
        exchangeId: 1, //Binance
        isActive: true,
        quoteCurrencyId: 1, //USDT
      },
    };

    return marketApi.postAppMarketQuery(request).pipe(
      map((next) => {
        this.allMarkets = next;
        if (this.defaultMarketIds !== null) {
          this.markets = this.allMarkets.filter(
            (market) => this.defaultMarketIds && this.defaultMarketIds.includes(market.id ?? 0)
          );
        } else {
          this.markets = this.allMarkets;
        }

        return next;
      })
    );
  }

  //NOTE: We use the first market's hodl data, we need one to show the charts date range
  //      (even if hodl data is not shown for multi market scenario)
  getHodl(candleSize: string) {
    if (!this.markets.length) {
      return;
    }

    const request: PostAppStrategyoptimizerHodlRequest = {
      hodlRequest: {
        from: this.from,
        to: this.to,
        candleSize: candleSize,
        marketId: this.markets[0]?.id ?? 0,
      },
    };

    strategyOptimizerApi.postAppStrategyoptimizerHodl(request).subscribe((next) => {
      if (candleSize == "1w") {
        //@ts-ignore
        this.weekHodlData = next;
      }
      if (candleSize == "1mo") {
        //@ts-ignore
        this.monthHodlData = next;
      }
    });
  }

  runBacktest(marketId: number) {
    //TODO showLong should be passed, but backtest does not support it yet.
    const request: PostAppStrategyrunCreateRequest = {
      strategyRunExecuteRequest: {
        from: this.from,
        to: this.to,
        candleSize: this.candleSize,
        marketIds: [marketId],
        strategyId: this.selectedEntryStrategy?.template?.strategy?.id ?? 0,
        exitStrategyId: this.selectedExitStrategy?.template?.strategy?.id ?? 0,
        entryStrategyParams: this.selectedEntryStrategy?.data.params.join(",") ?? "",
        exitStrategyParams: this.selectedExitStrategy?.data.params.join(",") ?? "",
        strategyInitialBalance: { exchangeId: 0, currencyId: 0, value: 10000 },
        saveOnlySummary: false,
      },
    };

    this.backtestLoading = true;
    strategyRunApi.postAppStrategyrunCreate(request).subscribe((next) => {
      this.backtestLoading = false;
      let routeData = this.$router.resolve({
        name: "strategyRun",
        params: { strategyRunId: next.strategyRunId?.toString() ?? "" },
      });
      window.open(routeData.href, "_blank");
    });
  }
}
