
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import EntryStatsResultsTextDisplay from "@/components/StrategyOptimizer/EntryStatsResultsTextDisplay.vue";
import { mdiArrowBottomRightThick, mdiArrowTopRightThick, mdiClockCheckOutline, mdiDotsVertical } from "@mdi/js";
import { Market, StatsDetailedResultAll } from "next-trader-api";
import { Stage2ResultsByMarket } from "@/views/StrategyOptimizerFinetune.vue";
import EntryStatsListTabChip from "@/components/StrategyOptimizer/EntryStatsListTabChip.vue";
import EntryStatsListTabChipDrawDown from "@/components/StrategyOptimizer/EntryStatsListTabChipDrawDown.vue";
import EntryStatsResultsTextDisplayEmpty from "@/components/StrategyOptimizer/EntryStatsResultsTextDisplayEmpty.vue";
import { debounce } from "lodash-es";

type ListItem = {
  market: Market;
  dataAvailable: boolean;
  sumValue?: number;
  cagr?: number;
  maxDrawDown?: number;
  positionCount?: number;
};

@Component({
  components: {
    EntryStatsResultsTextDisplayEmpty,
    EntryStatsListTabChipDrawDown,
    EntryStatsListTabChip,
    EntryStatsResultsTextDisplay,
  },
})
export default class EntryStatsListTab extends Vue {
  @Prop()
  showLong?: boolean;

  @Prop()
  stage2ResultAll?: StatsDetailedResultAll;

  @Prop()
  stage2ResultsByMarket?: Stage2ResultsByMarket[];

  @Prop()
  selectedMarket?: Market;

  icons = {
    mdiArrowTopRightThick: mdiArrowTopRightThick,
    mdiArrowBottomRightThick: mdiArrowBottomRightThick,
    mdiDotsVertical: mdiDotsVertical,
    mdiClockCheckOutline: mdiClockCheckOutline,
  };

  headers = [
    { text: "Market", value: "market.name", width: 200 },
    { text: "Profit", value: "sumValue", width: 110 },
    { text: "Positions", value: "positionCount", width: 110 },
    { text: "Cagr", value: "cagr", width: 200 },
    { text: "Max drawdown", value: "maxDrawDown" },
    { text: "Actions", value: "actions", width: 95 },
  ];

  items?: ListItem[] = [];

  tableHeight = 0;

  created() {
    this.updateData();
  }

  @Watch("showLong")
  @Watch("stage2ResultsByMarket")
  onUpdateNeeded() {
    this.debouncedUpdateData();
  }

  debouncedUpdateData = debounce(this.updateData, 100, { leading: true });

  updateData() {
    this.items = this.stage2ResultsByMarket?.map((stage2ResultByMarket) => {
      if (!stage2ResultByMarket.stage2Result) {
        const listItem: ListItem = {
          market: stage2ResultByMarket.market,
          dataAvailable: false,
        };
        return listItem;
      }

      const result = this.showLong
        ? stage2ResultByMarket.stage2Result.longResult
        : stage2ResultByMarket.stage2Result.shortResult;
      const listItem: ListItem = {
        market: stage2ResultByMarket.market,
        dataAvailable: true,
        sumValue: result.stats.sumValue,
        cagr: result.summary.cagr,
        maxDrawDown: result.summary.drawDown.maxDrawDown,
        positionCount: result.summary.positions.length,
      };
      return listItem;
    });
  }

  clickMarket(item: ListItem | null) {
    if (item !== null && !item.dataAvailable) {
      return;
    }

    let market = null;
    if (item !== null) {
      market = item.market;
    }
    this.$emit("update:selectedMarket", market);
  }

  onResize() {
    const footerHeight = 45;
    setTimeout(() => {
      this.tableHeight = this.$refs.tableParent?.clientHeight - footerHeight;
    });
  }

  runBacktest(item: ListItem) {
    this.$emit("runBacktest", item.market.id);
  }
}
