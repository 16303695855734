
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { GetStrategyResponseStrategy } from "next-trader-api";
import { StrategySelectorItem } from "@/components/StrategyOptimizer/model/StrategySelectorItem";
import BackTest from "@/views/BackTest.vue";
@Component({
  components: { BackTest },
})
export default class StrategySelector extends Vue {
  @Prop()
  label?: string;

  @Prop()
  allStrategies?: Array<GetStrategyResponseStrategy>;

  @Prop()
  item?: StrategySelectorItem;

  newStrategy: GetStrategyResponseStrategy | null = null;

  mounted() {
    this.newStrategy = this.item?.template || null;
  }

  change() {
    this.$emit("update:item", {
      template: this.newStrategy,
      data: {
        name: this.newStrategy?.name,
        params: this.newStrategy?.params.map((param) => param.defaultValue || ""),
      },
    });
  }
}
