import { render, staticRenderFns } from "./ColoredLabel.vue?vue&type=template&id=3c90fcc6&scoped=true&"
import script from "./ColoredLabel.vue?vue&type=script&lang=ts&"
export * from "./ColoredLabel.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "3c90fcc6",
  null
  
)

export default component.exports