
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({})
export default class EntryStatsListTabChip extends Vue {
  @Prop()
  value?: number;

  @Prop()
  positionCount?: number;

  @Prop()
  label?: string;
}
