
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { strategyOptimizerApi } from "@/plugins/api";
import { StrategyOptimizeResultItem } from "api/models";
import { orderBy } from "lodash-es";

@Component({})
export default class StrategyFinetunerResultItems extends Vue {
  @Prop()
  resultItem?: StrategyOptimizeResultItem;

  selectedResultItemId?: number = null;

  resultItems: Array<StrategyOptimizeResultItem> = [];
  sortByField = "sumValue";
  sortByFieldDirection = "desc";

  get resultItemsSorted() {
    return orderBy(this.resultItems, [(item) => item[this.sortByField]], [this.sortByFieldDirection]);
  }

  created() {
    this.selectedResultItemId = this.resultItem.id;

    strategyOptimizerApi
      .getAppStrategyoptimizerGetresultitems({
        optimizeResultId: this.resultItem?.strategyOptimizeResult.id,
      })
      .subscribe((next) => (this.resultItems = next));
  }

  selectItem(id) {
    this.selectedResultItemId = id;
    this.$emit("select-item", id);
  }

  setSortBy(fieldName) {
    if (this.sortByField !== fieldName) {
      this.sortByField = fieldName;
      if (fieldName === "maxDrawDown") {
        this.sortByFieldDirection = "asc";
      } else {
        this.sortByFieldDirection = "desc";
      }
    } else {
      this.sortByFieldDirection === "desc" ? (this.sortByFieldDirection = "asc") : (this.sortByFieldDirection = "desc");
    }
  }
}
