
import BarChart from "@/components/StrategyOptimizer/BarChart.vue";
import ColoredLabel from "@/components/StrategyOptimizer/ColoredLabel.vue";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import EntryStatsResultsTextDisplay from "@/components/StrategyOptimizer/EntryStatsResultsTextDisplay.vue";
import SumProfitChart from "@/components/StrategyOptimizer/SumProfitChart.vue";
import { mdiArrowBottomRightThick, mdiArrowTopRightThick } from "@mdi/js";
import { StatsDetailedResult, StatsDetailedResultStat } from "next-trader-api";

type BarData = [string, number, number, number, number];

@Component({
  components: {
    SumProfitChart,
    EntryStatsResultsTextDisplay,
    ColoredLabel,
    BarChart,
  },
})
export default class EntryStatsTab extends Vue {
  @Prop()
  calculateResultLong?: StatsDetailedResult[];

  @Prop()
  calculateResultShort?: StatsDetailedResult[];

  @Prop()
  weekHodlData?: any;

  @Prop()
  monthHodlData?: any;

  @Prop()
  showLong?: boolean;

  @Prop()
  singleMarket?: boolean;

  weekStatsLong?: { [key: string]: StatsDetailedResultStat };
  weekStatsShort?: { [key: string]: StatsDetailedResultStat };
  weekBarDataLong: BarData[] = [];
  weekBarDataShort: BarData[] = [];

  monthStatsLong?: { [key: string]: StatsDetailedResultStat };
  monthStatsShort?: { [key: string]: StatsDetailedResultStat };
  monthBarDataLong: BarData[] = [];
  monthBarDataShort: BarData[] = [];

  calculateOneResultLong?: StatsDetailedResult;
  calculateOneResultShort?: StatsDetailedResult;

  icons = {
    mdiArrowTopRightThick: mdiArrowTopRightThick,
    mdiArrowBottomRightThick: mdiArrowBottomRightThick,
  };

  mounted() {
    this.calculateBars();
  }

  calculateBars() {
    if (!this.calculateResultLong?.length || !this.calculateResultShort?.length) {
      return;
    }

    this.calculateOneResultLong = this.calculateResultLong[0];
    this.calculateOneResultShort = this.calculateResultShort[0];

    // WEEKLY
    this.weekStatsLong = this.calculateOneResultLong.weekStats;
    this.weekStatsShort = this.calculateOneResultShort.weekStats;
    this.weekBarDataLong = [];
    this.weekBarDataShort = [];

    let firstHodlItem = null;
    for (let [index, weekStat] of Object.entries(this.weekStatsLong)) {
      const weekHodl = this.weekHodlData && this.weekHodlData[index] ? this.weekHodlData[index] : 0;

      const positiveValue = weekStat.sumValue >= 0 ? Math.round(weekStat.sumValue * 100 * 100) / 100 : 0;
      const negativeValue = weekStat.sumValue < 0 ? Math.round(weekStat.sumValue * 100 * 100) / 100 : 0;
      const weekBarSum = Math.round(weekStat.sumValueAccumulated * 100 * 100) / 100;

      if (firstHodlItem === null) {
        firstHodlItem = weekHodl;
      }
      const weekHodlPercent = Math.round((weekHodl / firstHodlItem - 1) * 100 * 100) / 100;

      this.weekBarDataLong.push([index, positiveValue, negativeValue, weekBarSum, weekHodlPercent]);
    }

    firstHodlItem = null;
    for (const [index, weekStat] of Object.entries(this.weekStatsShort)) {
      const weekHodl = this.weekHodlData && this.weekHodlData[index] ? this.weekHodlData[index] : 0;

      const positiveValue = weekStat.sumValue >= 0 ? Math.round(weekStat.sumValue * 100 * 100) / 100 : 0;
      const negativeValue = weekStat.sumValue < 0 ? Math.round(weekStat.sumValue * 100 * 100) / 100 : 0;
      const weekBarSum = Math.round(weekStat.sumValueAccumulated * 100 * 100) / 100;

      if (firstHodlItem === null) {
        firstHodlItem = weekHodl;
      }
      const weekHodlPercent = Math.round((weekHodl / firstHodlItem - 1) * 100 * 100) / 100;

      this.weekBarDataShort.push([index, positiveValue, negativeValue, weekBarSum, weekHodlPercent]);
    }

    // MONTHLY
    this.monthStatsLong = this.calculateOneResultLong.monthStats;
    this.monthStatsShort = this.calculateOneResultShort.monthStats;
    this.monthBarDataLong = [];
    this.monthBarDataShort = [];

    firstHodlItem = null;
    for (const [index, monthStat] of Object.entries(this.monthStatsLong)) {
      const monthHodl = this.monthHodlData && this.monthHodlData[index] ? this.monthHodlData[index] : 0;

      const positiveValue = monthStat.sumValue >= 0 ? Math.round(monthStat.sumValue * 100 * 100) / 100 : 0;
      const negativeValue = monthStat.sumValue < 0 ? Math.round(monthStat.sumValue * 100 * 100) / 100 : 0;
      const monthBarSum = Math.round(monthStat.sumValueAccumulated * 100 * 100) / 100;

      if (firstHodlItem === null) {
        firstHodlItem = monthHodl;
      }
      const monthHodlPercent = Math.round((monthHodl / firstHodlItem - 1) * 100 * 100) / 100;

      this.monthBarDataLong.push([index, positiveValue, negativeValue, monthBarSum, monthHodlPercent]);
    }

    firstHodlItem = null;
    for (const [index, monthStat] of Object.entries(this.monthStatsShort)) {
      const monthHodl = this.monthHodlData && this.monthHodlData[index] ? this.monthHodlData[index] : 0;

      const positiveValue = monthStat.sumValue >= 0 ? Math.round(monthStat.sumValue * 100 * 100) / 100 : 0;
      const negativeValue = monthStat.sumValue < 0 ? Math.round(monthStat.sumValue * 100 * 100) / 100 : 0;
      const monthBarSum = Math.round(monthStat.sumValueAccumulated * 100 * 100) / 100;

      if (firstHodlItem === null) {
        firstHodlItem = monthHodl;
      }
      const monthHodlPercent = Math.round((monthHodl / firstHodlItem - 1) * 100 * 100) / 100;

      this.monthBarDataShort.push([index, positiveValue, negativeValue, monthBarSum, monthHodlPercent]);
    }
  }
}
