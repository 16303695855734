
//import "echarts";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

const index = {
  date: 0,
  positiveValue: 1,
  negativeValue: 2,
  sumBar: 3,
  closePrice: 4,
};

@Component
export default class BarChart extends Vue {
  @Prop()
  barData: any;

  @Watch("barData")
  onBarDataChanged() {
    this.options.dataset.source = this.barData;
  }

  mounted() {
    this.options.dataset.source = this.barData;
  }

  private options = {
    animation: false,
    dataset: {
      source: [],
    },
    tooltip: {
      trigger: "axis",
      axisPointer: {
        type: "line",
      },
    },
    grid: [
      {
        top: 20,
        left: "7%",
        right: "7%",
        bottom: 20,
      },
    ],
    xAxis: [
      {
        type: "category",
        scale: true,
        axisTick: {
          show: false,
        },
        axisLabel: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            opacity: 0.5,
          },
        },
      },
    ],
    yAxis: [
      {
        scale: true,
        axisLabel: {
          formatter: "{value}%",
        },
        axisTick: {
          show: false,
        },
        axisLine: {
          lineStyle: {
            opacity: 0.5,
          },
        },
        splitLine: {
          lineStyle: {
            opacity: 0.4,
          },
        },
      },
    ],
    /*dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 10,
      },
    ],*/
    series: [
      {
        name: "profit",
        type: "bar",
        stack: "here",
        itemStyle: {
          color: "#75b210",
        },
        large: true,
        encode: {
          x: index.date,
          y: index.positiveValue,
        },
      },
      {
        name: "loss",
        type: "bar",
        stack: "here",
        itemStyle: {
          color: "#e1191d",
        },
        large: true,
        encode: {
          x: index.date,
          y: index.negativeValue,
        },
      },
    ],
  };
}
