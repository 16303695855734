
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import PositionHistory from "@/components/PositionHistory.vue";
import { IndicatorConfig, Market, Position, StatsDetailedResultAll, SumValueOptimizerItem } from "next-trader-api";
import TVChartContainer from "@/components/StrategyRun/TVChartContainer.vue";
import dayjs from "dayjs";
import { MyBaseDatafeed } from "@/service/tv-charting-library/my-base-datafeed";
import { Pane, Splitpanes } from "splitpanes";
import EntryStatsListTabChipDrawDown from "@/components/StrategyOptimizer/EntryStatsListTabChipDrawDown.vue";
import EntryStatsListTabChip from "@/components/StrategyOptimizer/EntryStatsListTabChip.vue";
import { Stage2ResultsByMarket } from "@/views/StrategyOptimizerFinetune.vue";

type ListItem = {
  sumValue?: number;
  cagr?: number;
  maxDrawDown?: number;
  positionCount?: number;
};

@Component({
  components: {
    EntryStatsListTabChip,
    EntryStatsListTabChipDrawDown,
    TVChartContainer,
    PositionHistory,
    Splitpanes,
    Pane,
  },
})
export default class StrategyFinetunerPositions extends Vue {
  @Prop()
  showLong?: boolean;

  @Prop()
  candleSize?: string;

  @Prop()
  to?: string;

  @Prop()
  markets?: Market[];

  @Prop()
  selectedMarket?: Market;

  @Prop()
  stage2ResultAll?: StatsDetailedResultAll | null;

  @Prop()
  stage2ResultsByMarket?: Stage2ResultsByMarket[];

  @Prop()
  entryIndicatorConfigs?: Array<IndicatorConfig>;

  @Prop()
  exitIndicatorConfigs?: Array<IndicatorConfig>;

  activePosition?: Position | null = null;

  positions: Position[] = [];
  stage2Result: StatsDetailedResultAll | null = null;
  sumValueOptimizerItems: SumValueOptimizerItem[] = [];

  listItem: ListItem | null = null;

  datafeed?: MyBaseDatafeed;

  created() {
    this.createDatafeed();
    this.clearPositions();
    this.updatePositions();
  }

  lastTempPositionId = 0;

  onPositionsChangedAll() {
    if (!this.selectedMarket) {
      this.updatePositions();
    }
  }

  onPositionsChangedByMarket(market: Market) {
    if (market.id === this.selectedMarket?.id) {
      this.updatePositions();
    }
  }

  @Watch("selectedMarket")
  @Watch("showLong")
  onPositionsChanged() {
    this.clearPositions();
    this.updatePositions();
  }

  @Watch("to")
  onToChanged() {
    this.updateDatafeed();
  }

  clearPositions() {
    this.positions = [];
    this.listItem = null;
    const tvChartContainer: TVChartContainer = this.$refs.tvChart as TVChartContainer;
    if (tvChartContainer) {
      tvChartContainer.clearPositions();
    }
  }

  updatePositions() {
    if (this.selectedMarket) {
      const stage2ResultByMarket = this.stage2ResultsByMarket?.find(
        (stage2ResultByMarket) => stage2ResultByMarket.market.id === this.selectedMarket?.id
      );
      this.stage2Result = stage2ResultByMarket?.stage2Result || null;
    } else {
      this.stage2Result = this.stage2ResultAll || null;
    }
    this.positions = this.showLong
      ? this.stage2Result?.longResult.summary.positions ?? []
      : this.stage2Result?.shortResult.summary.positions ?? [];

    this.sumValueOptimizerItems = this.showLong
      ? this.stage2Result?.longResult.sumValueOptimizerItems ?? []
      : this.stage2Result?.shortResult.sumValueOptimizerItems ?? [];

    const result = this.showLong ? this.stage2Result?.longResult : this.stage2Result?.shortResult;
    if (result !== undefined) {
      this.listItem = {
        sumValue: result?.stats.sumValue,
        cagr: result?.summary.cagr,
        maxDrawDown: result?.summary.drawDown.maxDrawDown,
        positionCount: result?.summary.positions.length,
      };
    } else {
      this.listItem = null;
    }

    for (const position of this.positions) {
      position.id = ++this.lastTempPositionId;
    }
  }

  createDatafeed() {
    const maxEndDate = dayjs.utc(this.to).add(1, "day").unix();
    this.datafeed = new MyBaseDatafeed(maxEndDate);
  }

  updateDatafeed() {
    const maxEndDate = dayjs.utc(this.to).add(1, "day").unix();
    this.datafeed?.setMaxEndDate(maxEndDate);
  }

  clickPosition(position: Position) {
    if (this.activePosition && this.activePosition === position) {
      this.activePosition = undefined;
    } else {
      this.activePosition = position;
    }

    const tvChartContainer: TVChartContainer = this.$refs.tvChart as TVChartContainer;
    tvChartContainer.setActivePosition(this.activePosition);
  }

  //@Watch("sumValueOptimizerItems", { deep: true })
  sumValueOptimizerItemsChanged() {
    if (this.sumValueOptimizerItems.length === 0) {
      return;
    }

    const box1 = this.getBoxes("volumePrevRate", 10);
    const box2 = this.getBoxes("priceChangePrevRate", 10);
    const box3 = this.getBoxes("volumeRate", 10);
    const box4 = this.getBoxes("priceChangeRate", 10);

    const startedAt = new Date();
    const values = [];
    for (const min1 of box1) {
      for (const min2 of box2) {
        for (const min3 of box3) {
          for (const min4 of box4) {
            let sumValueAll = 1;
            let count = 0;
            let positionsObj = {};
            let positionsObjExc = {};
            let positionUntilBarIndex = 0;
            for (const item of this.sumValueOptimizerItems) {
              if (item.entryBarIndex < positionUntilBarIndex) {
                positionsObjExc[item.entryBarIndex + " - " + item.exitBarIndex] = [
                  item.entryDate,
                  item.sumValue,
                  positionUntilBarIndex,
                ];
                continue;
              }

              // @ts-ignore
              if (item.indicatorValues?.volumePrevRate < min1) {
                continue;
              }
              // @ts-ignore
              if (item.indicatorValues?.priceChangePrevRate < min2) {
                continue;
              }
              // @ts-ignore
              if (item.indicatorValues?.volumeRate < min3) {
                continue;
              }
              // @ts-ignore
              if (item.indicatorValues?.priceChangeRate < min4) {
                continue;
              }

              positionUntilBarIndex = item.exitBarIndex;

              sumValueAll *= 1 + item.sumValue;

              count++;
              positionsObj[item.entryBarIndex + " - " + item.exitBarIndex] = [
                item.entryDate,
                item.sumValue,
                item.indicatorValues,
              ];
            }
            --sumValueAll;

            if (count > 0 && sumValueAll >= 0) {
              sumValueAll = Math.round(sumValueAll * 100 * 100) / 100;
              values.push({
                v: sumValueAll,
                s: sumValueAll + "%  [" + min1 + ", " + min3 + ", " + min2 + ", " + min4 + "]  " + count + " position",
                p: positionsObj,
                pe: positionsObjExc,
              });
            }
          }
        }
      }
    }
    const stoppedAt = new Date();
    console.log("elapsed", stoppedAt.getTime() - startedAt.getTime() + "ms");

    values.sort((a, b) => b.v - a.v);

    const groups = new Map();
    for (const value of values) {
      const key = value.v + "%";
      if (!groups.has(key)) {
        groups.set(key, []);
      }
      groups.get(key).push(value);
    }

    //const bestValues = values.slice(0, 100);

    console.log(groups);

    //"volumeRate",
    //"priceChangePrevRate",
    //"priceChangeRate",
    //"spikePrev",
  }

  private getBoxes(name: string, boxCount: number) {
    // @ts-ignore
    let min = this.sumValueOptimizerItems[0].indicatorValues[name];
    // @ts-ignore
    let max = this.sumValueOptimizerItems[0].indicatorValues[name];

    for (const item of this.sumValueOptimizerItems) {
      // @ts-ignore
      const value = item.indicatorValues[name];
      if (value < min) {
        min = value;
      }
      if (value > max) {
        max = value;
      }
    }

    const boxSize = (max - min) / boxCount;

    const boxes = [];
    for (let i = 0; i < boxCount; i++) {
      boxes.push(Math.round(min + i * boxSize));
    }

    return boxes;
  }
}
