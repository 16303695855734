
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { GetStrategyResponseStrategy } from "api/models";
import { StrategySelectorItem } from "@/components/StrategyOptimizer/model/StrategySelectorItem";
import StrategySelector from "@/components/StrategyOptimizer/StrategySelector.vue";
@Component({
  components: { StrategySelector },
})
export default class StrategyFinetunerStrategySelector extends Vue {
  @Prop()
  entryStrategies?: Array<GetStrategyResponseStrategy>;

  @Prop()
  exitStrategies?: Array<GetStrategyResponseStrategy>;

  @Prop()
  selectedEntryStrategy?: StrategySelectorItem;

  @Prop()
  selectedExitStrategy?: StrategySelectorItem;
}
