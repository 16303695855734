
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";

@Component({})
export default class ColoredLabel extends Vue {
  @Prop()
  value?: number;

  @Prop()
  label?: string;

  @Prop()
  suffix?: string;
}
