
import { EntityId, IChartingLibraryWidget, StudyInputValue } from "@/plugins/tv_charting_library/charting_library";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";
import TvChartContainerBase from "@/components/TVChartContainerBase";
import { CalculateRequestStrategy } from "api/models";
import { MyBaseDatafeed } from "@/service/tv-charting-library/my-base-datafeed";

@Component({
  components: { TvChartContainerBase },
})
export default class TVChartContainer extends Vue {
  @Prop({ default: "ETH/USDT" })
  symbol?: string;

  @Prop({ default: "1h" })
  candleSize?: string;

  datafeed = new MyBaseDatafeed();

  @Prop()
  entryStrategy?: CalculateRequestStrategy;

  entryHandlerEntityId: EntityId | null = null;

  @Prop()
  exitHandlerName?: string;

  @Prop()
  exitHandlerParams?: ExitHandleParams;
  exitHandlerEntityId: EntityId | null = null;

  tvWidget?: IChartingLibraryWidget;

  @Watch("exitHandlerParams", { deep: true })
  onExitHandlerParamsChanged() {
    if (!this.exitHandlerEntityId || !this.exitHandlerParams) {
      return;
    }

    this.tvWidget
      ?.chart()
      .getStudyById(this.exitHandlerEntityId)
      .applyOverrides({
        "input 1": this.exitHandlerName,
        "input 2": this.exitHandlerParams.targetPricePercent / 100,
        "input 3": this.exitHandlerParams.stopLossPercent / 100,
        "input 4": this.exitHandlerParams.candlesToExpire,
      });
  }

  @Watch("exitHandlerName")
  onExitHandlerNameChanged() {
    if (!this.exitHandlerEntityId || !this.exitHandlerName) {
      return;
    }

    this.onExitHandlerParamsChanged();
  }

  @Watch("entryStrategy", { deep: true })
  onEntryStrategyChanged() {
    if (!this.entryHandlerEntityId || !this.entryStrategy) {
      return;
    }

    const name = this.entryStrategy?.name;
    const params = this.entryStrategy?.params;

    this.tvWidget
      ?.chart()
      .getStudyById(this.entryHandlerEntityId)
      .applyOverrides({
        "input 1": name,
        "input 2": params[0] || "",
        "input 3": params[1] || "",
        "input 4": params[2] || "",
        "input 5": params[3] || "",
        "input 6": params[4] || "",
        "input 7": params[5] || "",
        "input 8": params[6] || "",
        "input 9": params[7] || "",
        "input 10": params[8] || "",
      });
  }

  onWidgetCreated(tvWidget: IChartingLibraryWidget) {
    this.tvWidget = tvWidget;

    let inputsEntry: StudyInputValue[] = [];
    if (this.exitHandlerParams) {
      inputsEntry = [this.entryStrategy?.name || ""].concat(this.entryStrategy?.params || []);
    }

    let inputsExit: StudyInputValue[] = [];
    if (this.exitHandlerParams) {
      inputsExit = [this.exitHandlerName || ""].concat([
        this.exitHandlerParams?.targetPricePercent / 100,
        this.exitHandlerParams?.stopLossPercent / 100,
        this.exitHandlerParams?.candlesToExpire,
      ]);
    }

    tvWidget.onChartReady(() => {
      tvWidget
        .chart()
        .createStudy("Exit Handler Indicator", false, false, inputsExit)
        .then((entityId: EntityId | null) => {
          this.exitHandlerEntityId = entityId;
        });

      tvWidget
        .chart()
        .createStudy("Entry Handler Indicator", false, false, inputsEntry)
        .then((entityId: EntityId | null) => {
          this.entryHandlerEntityId = entityId;
        });
    });
  }
}

export class ExitHandleParams {
  targetPricePercent: number;
  stopLossPercent: number;
  candlesToExpire: number;

  constructor(targetPricePercent: number, stopLossPercent: number, candlesToExpire: number) {
    this.targetPricePercent = targetPricePercent;
    this.stopLossPercent = stopLossPercent;
    this.candlesToExpire = candlesToExpire;
  }
}
