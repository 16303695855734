
import Component from "vue-class-component";
import Vue from "vue";
import { Prop } from "vue-property-decorator";
import { StatsDetailedResultStat, StatsDetailedResultSummary } from "next-trader-api";

@Component({})
export default class EntryStatsResultsTextDisplay extends Vue {
  @Prop()
  stats?: StatsDetailedResultStat;

  @Prop()
  summary?: StatsDetailedResultSummary;
}
