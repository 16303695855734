
//import "echarts";
import Component from "vue-class-component";
import Vue from "vue";
import { Prop, Watch } from "vue-property-decorator";

const index = {
  groupMin: 0,
  groupMax: 1,
  long: 2,
  short: 3,
  longAvg: 4,
  shortAvg: 5,
};

@Component
export default class ExitChart extends Vue {
  @Prop()
  exitData: any;

  @Prop()
  isLong?: boolean;

  @Watch("exitData")
  onExitDataChanged() {
    this.options.dataset.source = this.exitData;
  }

  mounted() {
    this.options.dataset.source = this.exitData;
  }

  private options = {
    animation: false,
    dataset: {
      source: [],
    },
    grid: [
      {
        top: 20,
        left: "8%",
        right: "6%",
        bottom: 20,
      },
    ],
    xAxis: [
      {
        type: "category",
        scale: true,
      },
    ],
    yAxis: [
      {
        scale: true,
        axisLabel: {
          formatter: "{value}%",
        },
      },
      {
        scale: true,
        splitLine: {
          show: false,
        },
        axisLabel: {
          formatter: "{value}%",
        },
        min: -20,
        //max: 20
      },
    ],
    dataZoom: [
      {
        type: "inside",
        start: 0,
        end: 10,
      },
    ],
    series: [
      {
        name: "value",
        type: "bar",
        itemStyle: {
          color: "blue",
        },
        encode: {
          x: index.groupMin,
          y: this.isLong ? index.long : index.short,
        },
      },
      {
        name: "value",
        type: "line",
        itemStyle: {
          color: "red",
        },
        yAxisIndex: 1,
        encode: {
          x: index.groupMin,
          y: this.isLong ? index.longAvg : index.shortAvg,
        },
        areaStyle: {},
      },
    ],
  };
}
